import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class WorkbookService {
  private apiPrefix = '/client/workbooks';

  constructor(
    private api: ApiService
  ) {
  }

  getWorkbooks(qs = '') {
    return this.api.get(`${this.apiPrefix}${qs}`);
  }
}
